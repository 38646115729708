/* react-transition-group animation styles */
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 350ms ease-in;
}

@font-face {
  font-family: "Favorit-Regular";
  src: url("./fonts/Favorit-Regular.otf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Favorit-Medium";
  src: url("./fonts/Favorit-Medium.otf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Mercury-Bold";
  src: url("./fonts/MercuryDisplay-Bold.otf");
  font-weight: bold;
  font-style: normal;
}
